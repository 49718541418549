import React, { useState } from 'react'
import SidebarNavigation from '@/components/base/SidebarNavigation'
import { useRouter } from 'next/router'
import Logo from '@/components/base/Logo'
import Button from '@/components/base/Button'

import styles from '@/styles/Sidebar.module.css'
import { useSession } from '@/contexts/Session'

const Sidebar = () => {
    const router = useRouter()
    const [activeMenu, setActiveMenu] = useState(0)
    const { scopes, user } = useSession()
    const menuItems = [
        {
            icon: 'icon-hex',
            title: 'PROJECTS',
            path: '/projects',
            query: {
                type: 'schedule',
                standard: '',
            },
        },
        {
            icon: 'icon-backlog',
            title: 'HARDWARE SETS',
            path: '/hardware-sets',
        },
        {
            icon: 'icon-folder',
            title: 'PRODUCTS',
            path: '/products',
            query: {
                productGroup: 'standard',
            },
        },
        {
            icon: 'icon-building',
            title: 'COMPANY',
            path: '/company',
        },
        {
            icon: 'icon-address-book',
            title: 'CONTACTS',
            path: '/contacts',
        },
        {
            icon: 'icon-users',
            title: 'USERS',
            path: '/users',
            query: {
                isUserListing: true,
                status: 'active',
            },
        },
        {
            icon: 'icon-settings',
            title: 'SETTINGS',
            path: '/configurations',
        },
    ].filter((navigation) => {
        if (
            user?.roleTitle === 'System User' &&
            navigation?.title === 'PROJECTS'
        ) {
            if (user?.projectTypeAccess !== 'all') {
                navigation.query.type = user?.projectTypeAccess
            }
            if (user?.standardAccess !== 'both') {
                navigation.query.standard = user?.standardAccess
            }
        }
        return (
            navigation.label === 'Sign out' ||
            scopes.includes(navigation?.path?.slice(1)?.replace('/', '-'))
        )
    })
    const handleClick = (index) => {
        setActiveMenu(index)
    }
    const activeNavigationClass = (link, isParent = true, index) => {
        const { asPath } = router
        if (isParent) {
            if (asPath.startsWith(link)) {
                return styles.active
            }
        } else {
            if (index === 0 && asPath !== link) {
                return ''
            } else if (asPath.startsWith(link)) {
                return styles.active
            }
        }
    }
    const { push } = useRouter()
    const { authToken, removeAuthToken } = useSession()

    const signOut = async () => {
        const signOutURL = `${process.env.NEXT_PUBLIC_API_URL}/auth/logout`

        const fetchOptions = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken?.accessToken}`,
            },
        }
        await fetch(signOutURL, fetchOptions)
        removeAuthToken('accessToken', {
            path: '/',
            sameSite: true,
        })
        push('/')
    }

    return (
        <>
            <div
                className={`${styles.sidebar} ${
                    user?.roleTitle === 'Product Library Admin' &&
                    '!justify-start pt-28'
                }`}
            >
                <Logo
                    link={
                        user?.roleTitle === 'Product Library Admin'
                            ? {
                                  pathname: '/products',
                                  query: {
                                      productGroup: 'standard',
                                  },
                              }
                            : {
                                  pathname: '/projects',
                                  query: {
                                      type: 'schedule',
                                      standard: '',
                                  },
                              }
                    }
                    src="/logo-inverse.svg"
                    width={48.53}
                    height={42}
                    className="w-[48.53px] h-[42px] absolute top-6"
                />
                <div className="scrollable max-h-[calc(100vh-222px)]">
                    <SidebarNavigation
                        activeMenu={activeMenu}
                        activeNavigationClass={activeNavigationClass}
                        menuItems={menuItems}
                        handleClick={handleClick}
                    />
                </div>
                <div className="absolute bottom-4 signout-section">
                    <Button
                        link="/"
                        text="SIGN OUT"
                        click={() => signOut()}
                        type="button"
                        icon="logout"
                        labelSize="text-xs"
                        position="flex-col"
                        iconStyle="static mb-3 hover:text-common-black"
                        variant="sidebarLink"
                        modification="px-0 hover:text-common-black"
                    />
                </div>
            </div>
        </>
    )
}

export default Sidebar
